/* You can add global styles to this file, and also import other style files */
@import "@taiga-ui/core/styles/taiga-ui-local";

* {
  box-sizing: border-box;
}

:root {
  --app-header-height: 3rem;
  --app-aside-width: 3rem;
}

.page-container {
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
}

form {
  max-width: 800px;

  .form-content {
    display: grid;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

[tuiAppearance][data-appearance="flat"],
[tuiAppearance][data-appearance="flat-destructive"],
[tuiAppearance][data-appearance="flat-grayscale"] {
  color: var(--tui-text-01);
}
